import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { getLoginRequestHeader, localStorageHelper } from '../../utils';
import {
    allCurrenciesSuccess,
    allCurrenciesByBrandSuccess,
    apiError,
    changeDefaultPasswordSuccess,
    changeNameSuccess,
    changePasswordSuccess,
    getAllCountriesSuccess,
    loginSuccess,
    logout,
    showErrorNotification,
    timezoneSuccess,
    updatePreferencesSuccess,
    UserTypes,
    DateOfLastRefrasheSuccessfull,
    getAllGamesByUserSuccess,
    getAllGameConfigurationsByUserSuccess,
} from '../actions/user-actions';
import { CasinoPerformanceReportTypes } from '../actions/backoffice/casino-performance-report-actions';
import { IAction } from '../../helpers/interfaces';
import { AUTHORITIES_MAP } from '../../utils/AuthoritiesMap';
import axios from 'axios';
import { SERVER_URL } from '../../utils/config';
import { ChecksumReportTypes } from '../actions/backoffice/checksum-report-actions';
import { GameHistoryTypes } from '../actions/backoffice/game-history-actions';
import { GamesReportTypes } from '../actions/backoffice/games-report-actions';
import { PlayerReportTypes } from '../actions/backoffice/player-report-actions';
import { PlayersReportTypes } from '../actions/backoffice/players-report-actions';

export const loginEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.LOGIN_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            const requestBody = {
                email: action.payload.email,
                password: action.payload.password,
            };

            const fromPromise = from(
                axios.post(`${SERVER_URL}/account/login`, requestBody, { headers }),
            );

            return fromPromise.pipe(
                mergeMap((response) => {
                    if (response.headers && response.headers.authorization) {
                        localStorage.setItem('authorization', response.headers.authorization);
                    }

                    return from([loginSuccess(), { type: UserTypes.INFO_REQUEST, payload: null }]);
                }),
                catchError((error) => of(apiError(error, UserTypes.LOGIN_REQUEST))),
            );
        }),
    );

export const infoEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.INFO_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            const fromPromise = from(axios.get(`${SERVER_URL}/account/info`, { headers }));

            return fromPromise.pipe(
                mergeMap((response: any) => {
                    const { authorities, preferences, email, name, role, brandFeatures } =
                        response.data;
                    const loadingQueue: string[] = ['INFO_SUCCESS'];

                    localStorageHelper
                        .setChainedValue('user.email', email)
                        .setChainedValue('user.name', name)
                        .setChainedValue('user.authorities', authorities)
                        .setChainedValue('user.preferences', preferences)
                        .setChainedValue('user.brandFeatures', brandFeatures)
                        .setChainedValue('user.role', role);

                    authorities.forEach((authority: any) => {
                        AUTHORITIES_MAP.forEach((mapAuthority: any) => {
                            if (authority === mapAuthority.name) {
                                mapAuthority.resources.forEach((resource: any) => {
                                    if (!loadingQueue.includes(resource)) {
                                        loadingQueue.push(resource);
                                    }
                                });
                            }
                        });
                    });

                    return loadingQueue.map((authority: any) => ({
                        type: authority,
                        payload: response.data,
                    }));
                }),
                catchError((error) => of(apiError(error, UserTypes.INFO_REQUEST))),
            );
        }),
    );

export const allCurrenciesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.GET_ALL_CURRENCIES_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            const fromPromise = from(axios.get(`${SERVER_URL}/currencies/all`, { headers }));

            return fromPromise.pipe(
                map((response) => {
                    return allCurrenciesSuccess({
                        response: response.data,
                        source: UserTypes.GET_ALL_CURRENCIES_REQUEST,
                    });
                }),
                catchError((error) => of(apiError(error, UserTypes.GET_ALL_CURRENCIES_REQUEST))),
            );
        }),
    );

export const allCurrenciesByOperatorEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.GET_ALL_CURRENCIES_BY_BRAND_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            const fromPromise = from(axios.get(`${SERVER_URL}/currencies/by-brand`, { headers }));

            return fromPromise.pipe(
                map((response) => {
                    return allCurrenciesByBrandSuccess({
                        response: response.data,
                        source: UserTypes.GET_ALL_CURRENCIES_BY_BRAND_REQUEST,
                    });
                }),
                catchError((error) =>
                    of(apiError(error, UserTypes.GET_ALL_CURRENCIES_BY_BRAND_REQUEST)),
                ),
            );
        }),
    );

export const timezoneEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.TIME_ZONE_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            const fromPromise = from(axios.get(`${SERVER_URL}/time-zone/list`, { headers }));

            return fromPromise.pipe(
                map((response) => {
                    localStorageHelper.setChainedValue('user.timezoneList', response.data);
                    return timezoneSuccess({
                        response: response.data,
                        source: UserTypes.TIME_ZONE_REQUEST,
                    });
                }),
                catchError((error) => of(apiError(error, UserTypes.TIME_ZONE_REQUEST))),
            );
        }),
    );

export const countriesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.GET_ALL_COUNTRIES_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            const fromPromise = from(axios.get(`${SERVER_URL}/country/all`, { headers }));

            return fromPromise.pipe(
                map((response) => {
                    localStorageHelper.setChainedValue('user.countries', response.data);

                    return getAllCountriesSuccess({
                        response: response.data,
                        source: UserTypes.GET_ALL_COUNTRIES_REQUEST,
                    });
                }),
                catchError((error) => of(apiError(error, UserTypes.GET_ALL_COUNTRIES_REQUEST))),
            );
        }),
    );

export const updatePreferencesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.UPDATE_PREFERENCES_REQUEST),
        mergeMap((action) => {
            const currentPreferences = localStorageHelper.getChainedValue('user.preferences');
            const headers = getLoginRequestHeader('application/json');
            const preferencesToUpdate = Object.keys(action.payload);

            preferencesToUpdate.forEach(
                (preference) => (currentPreferences[preference] = action.payload[preference]),
            );

            const fromPromise = from(
                axios.post(`${SERVER_URL}/account/update-preferences`, currentPreferences, {
                    headers,
                }),
            );

            return fromPromise.pipe(
                map(() => updatePreferencesSuccess(currentPreferences)),
                catchError((error) => of(apiError(error, UserTypes.UPDATE_PREFERENCES_REQUEST))),
            );
        }),
    );

export const changeUserNameEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.CHANGE_NAME_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            const fromPromise = from(
                axios.post(`${SERVER_URL}/account/change-name`, action.payload, {
                    headers,
                }),
            );

            return fromPromise.pipe(
                map((response) => changeNameSuccess(response.data)),
                catchError((error) => of(apiError(error, UserTypes.CHANGE_NAME_REQUEST))),
            );
        }),
    );

export const changeUserPasswordEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.CHANGE_PASSWORD_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            const fromPromise = from(
                axios.post(`${SERVER_URL}/account/change-password`, action.payload, { headers }),
            );

            return fromPromise.pipe(
                map((response) => changePasswordSuccess(response.data)),
                catchError((error) => of(apiError(error, UserTypes.CHANGE_PASSWORD_REQUEST))),
            );
        }),
    );

export const changeDefaultPasswordEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.CHANGE_DEFAULT_PASSWORD_REQUEST),
        mergeMap((action) => {
            const headers = { 'Content-Type': 'application/json' };
            const fromPromise = from(
                axios.post(`${SERVER_URL}/change-password`, action.payload, {
                    headers,
                }),
            );

            return fromPromise.pipe(
                map((response) => changeDefaultPasswordSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, UserTypes.CHANGE_DEFAULT_PASSWORD_REQUEST)),
                ),
            );
        }),
    );

export const errorEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.API_ERROR),
        mergeMap((action) => {
            const { payload } = action;
            let response;
            const actions = [];

            if (payload.response) {
                if (typeof payload.response === 'string') {
                    try {
                        response = JSON.parse(payload.response);
                    } catch (e) {
                        response = null;
                    }
                } else if (typeof payload.response === 'object') {
                    response = payload.response;
                }
            }

            if (
                response &&
                response.data &&
                response.data.error &&
                response.data.error.code === 3
            ) {
                actions.push(logout());
            }

            actions.push(showErrorNotification(payload));

            // Check for the existence of meta before using it
            if (action.meta && action.meta.sourceActionType) {
                actions.push({
                    type: action.meta.sourceActionType.replace('_REQUEST', '_FAILED'),
                    payload,
                });
            }

            return of(...actions);
        }),
    );

export const dateOfLastRefreshEpic = (action$: Observable<any>) =>
    action$.pipe(
        ofType(
            CasinoPerformanceReportTypes.CASINO_PERFORMANCE_REPORT_REQUEST,
            ChecksumReportTypes.CHECKSUM_REPORT_REQUEST,
            GameHistoryTypes.GAME_HISTORY_REQUEST,
            GamesReportTypes.GAMES_REPORT_REQUEST,
            PlayerReportTypes.PLAYER_REPORT_REQUEST,
            PlayersReportTypes.PLAYERS_REPORT_REQUEST,
        ),
        mergeMap(() => {
            return from(
                axios.get(`${SERVER_URL}/report-audit`, {
                    headers: getLoginRequestHeader('application/json'),
                }),
            ).pipe(
                map((response) => DateOfLastRefrasheSuccessfull(response.data)),
                catchError((error) => of(apiError(error, UserTypes.DATE_OF_LAST_REFRESH_REQUEST))),
            );
        }),
    );

export const allGamesByUserEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.GET_ALL_GAMES_BY_USER_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            return from(axios.get(`${SERVER_URL}/games/all`, { headers })).pipe(
                map((response) => getAllGamesByUserSuccess(response.data)),
                catchError((error) => of(apiError(error, UserTypes.GET_ALL_GAMES_BY_USER_REQUEST))),
            );
        }),
    );

export const allGameConfigurationsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UserTypes.GET_ALL_GAME_CONFIGURATIONS_BY_USER_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            return from(axios.get(`${SERVER_URL}/games/configurations`, { headers })).pipe(
                map((response) => getAllGameConfigurationsByUserSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, UserTypes.GET_ALL_GAME_CONFIGURATIONS_BY_USER_REQUEST)),
                ),
            );
        }),
    );
