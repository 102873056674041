import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, Select } from 'antd';
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import { PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { MainButton, SecondaryButton } from '../../../Components/CustomButtons/CustomButtons';
import { useTranslation } from 'react-i18next';
import { FormikField, FormikInput, FormikSwitch, FormikUpload } from './FormikFields';
import { currencyUtils, localStorageHelper } from '../../../utils';
import {
    ICountry,
    ICurrencyData,
    IGameItemData,
    ITimeZoneListItem,
} from '../../../helpers/interfaces';
import { Dispatch } from 'redux';
import {
    createPromotion,
    createPromotionWithCSV,
    getPromotions,
} from '../../../redux/actions/backoffice/promotions-actions';
import { connect, useSelector } from 'react-redux';
import { validationSchema } from './validationSchema';
import { Overlay } from '../../../Components/Overlay/Overlay';
import { getGameConfigurationsByUser } from '../../../redux/selectors/user-selectors';
import { getAllGameConfigurationsByUser } from '../../../redux/actions/user-actions';

interface IProps {
    createPromotion: Function;
    createPromotionWithCSV: Function;
    getGameConfigurations: Function;
}

const getBetSizes = (selectedGame: any, currentCurrency: any) => {
    if (!selectedGame || !currentCurrency) return [];
    const betConfig = selectedGame.betSizes[currentCurrency.id];
    if (!betConfig) return [];
    const parsedConfig = JSON.parse(betConfig);
    return parsedConfig.bets.map((bet: number) => bet * selectedGame.wager);
};

const getInitialValues = (): any => {
    return {
        campaign_name: '',
        promotion_type: 'free_rounds',
        timezone: 'Etc/UTC',
        start_at: '',
        end_at: '',
        countries: ['all'],
        players: null,
        file: null,
        isFile: false,
        game_name: [],
        currency: 'USD',
        bet: null,
        expires_in_days: '',
        number_of_free_rounds: '',
    };
};

const PromotionModal = ({ createPromotion, createPromotionWithCSV }: IProps) => {
    const { t } = useTranslation();
    const isMountedRef = useRef(true);
    const [mainModalVisible, setMainModalVisible] = useState(false);
    const [secondartModalVisible, setSecondartModalVisible] = useState(false);
    const [betSizes, setBetSizes] = useState<any>([]);
    const currencyList = useMemo(
        () => localStorageHelper.getChainedValue('user.currenciesByBrand'),
        [],
    );
    const setIsFile = useState(false)[1];
    const countriesList = localStorageHelper.getChainedValue('user.countries');
    const gamesList: any[] = useSelector(getGameConfigurationsByUser).filter(
        (game: IGameItemData) => game.freeRoundsEnabled,
    );

    const timezoneList = localStorageHelper.getChainedValue('user.timezoneList');
    const [filteredGamesList, setFilteredGamesList] = useState<any>([]);

    let initialValues = getInitialValues();

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema(t, false),
        onSubmit: () => {
            showSecondaryModal();
        },
    });

    const { values, setFieldValue } = formik;

    const handleCancel = () => {
        setMainModalVisible(false);
        formik.resetForm();
    };

    const estimatedTotalBets = useCallback(() => {
        const { players, bet, number_of_free_rounds, currency } = formik.values;
        return `${(
            (Number(players) * Number(bet) * Number(number_of_free_rounds)) /
            currencyUtils.getCurrencyDataByCode(currency).subunit
        ).toFixed(2)} ${currency}`;
    }, [formik.values]);

    const estimatedBetsPerPlayer = useCallback(() => {
        const { bet, number_of_free_rounds, currency } = formik.values;
        return `${(
            (Number(bet) * Number(number_of_free_rounds)) /
            currencyUtils.getCurrencyDataByCode(currency).subunit
        ).toFixed(2)} ${currency}`;
    }, [formik.values]);

    const handleCancelSecondary = () => {
        setSecondartModalVisible(false);
        setMainModalVisible(true);
    };

    const showSecondaryModal = () => {
        setMainModalVisible(false);
        setSecondartModalVisible(true);
    };

    const submitPromotion = (values: any) => {
        values.file ? createPromotionWithCSV(values) : createPromotion(values);

        setSecondartModalVisible(false);
        setMainModalVisible(false);
        formik.resetForm();
    };

    const handleSubmit = () => {
        if (Array.isArray(values.countries)) {
            if (values.countries.includes('all')) {
                values.countries = countriesList.map((country: ICountry) => country.alpha2Code);
            } else {
                values.countries = values.countries.map((countryName: any) => {
                    const foundCountry = countriesList.find(
                        (country: ICountry) => country.name === countryName,
                    );
                    return foundCountry ? foundCountry.alpha2Code : countryName;
                });
            }
        }

        if (Array.isArray(values.game_name)) {
            if (values.game_name.includes('all')) {
                values.game_name = filteredGamesList.map((game: IGameItemData) => game.id);
            } else {
                values.game_name = values.game_name.map(
                    (gameName: string) =>
                        gamesList.find(
                            (game: { name: string; id: number }) => game.name === gameName,
                        ).id,
                );
            }
        }

        if (isMountedRef.current) {
            if (values.file) {
                const fileReader = new FileReader();

                fileReader.onloadend = () => {
                    if (fileReader.result instanceof ArrayBuffer) {
                        const formData = new FormData();
                        formData.append(
                            'file',
                            new Blob([new Uint8Array(fileReader.result)], {
                                type: 'text/csv',
                            }),
                        );
                        values.file = formData;
                        submitPromotion(values);
                    }
                };

                fileReader.readAsArrayBuffer(values.file);
            } else {
                submitPromotion(values);
            }
        }
    };

    const showModal = () => {
        setMainModalVisible(true);
        initialValues = getInitialValues();
        formik.resetForm({ values: initialValues });
    };

    const handleSwitchChange = useCallback((checked: boolean) => {
        setIsFile(checked);
    }, [setIsFile]);

    useEffect(() => {
        if (gamesList && gamesList.length > 0 && values.currency) {
            const uniqueBets = new Set<number>();

            gamesList.forEach((game: IGameItemData) => {
                const betSizesForCurrency = getBetSizes(
                    game,
                    currencyList.find(
                        (currency: ICurrencyData) => currency.code === values.currency,
                    ),
                );
                betSizesForCurrency.forEach((bet: number) => uniqueBets.add(bet));
            });

            const sortedUniqueBets = Array.from(uniqueBets).sort((a: number, b: number) => a - b);

            if (JSON.stringify(sortedUniqueBets) !== JSON.stringify(betSizes)) {
                setBetSizes(sortedUniqueBets);

                if (values.bet !== sortedUniqueBets[0]) {
                    setFieldValue('bet', sortedUniqueBets[0]);
                }

                if (values.game_name.length !== 0) {
                    setFieldValue('game_name', []);
                }
            }
        }
    }, [
        gamesList,
        values.currency,
        currencyList,
        betSizes,
        setFieldValue,
        values.bet,
        values.game_name,
    ]);

    useEffect(() => {
        if (values.bet) {
            const filteredGames = gamesList.filter((game: IGameItemData) => {
                const betSizesForCurrency = getBetSizes(
                    game,
                    currencyList.find(
                        (currency: ICurrencyData) => currency.code === values.currency,
                    ),
                );
                return betSizesForCurrency.includes(values.bet);
            });

            if (JSON.stringify(filteredGames) !== JSON.stringify(filteredGamesList)) {
                setFilteredGamesList(filteredGames);
                const validSelectedGames = values.game_name.filter((gameName: string) =>
                    filteredGames.some((game) => game.name === gameName),
                );

                if (validSelectedGames.length !== values.game_name.length) {
                    setFieldValue('game_name', validSelectedGames);
                }
            }
        }
    }, [values.bet, values.game_name, currencyList, gamesList, filteredGamesList, setFieldValue, values.currency]);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (values.start_at && values.end_at) {
            const start = new Date(values.start_at);
            const end = new Date(values.end_at);
            const durationInMilliseconds = end.getTime() - start.getTime();
            const durationInMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
            const durationInDays = Math.ceil(durationInMinutes / (60 * 24));
            if (durationInDays >= 0) {
                setFieldValue('expires_in_days', durationInDays);
            }
        }
    }, [values.start_at, values.end_at, setFieldValue]);

    return (
        <div>
            <MainButton onClick={showModal}>
                <>
                    <PlusCircleOutlined />
                    {t('add_new_promotion')}
                </>
            </MainButton>
            <Modal
                destroyOnClose
                title={t('new_campaign_creation')}
                open={secondartModalVisible}
                footer={null}
                onCancel={handleCancelSecondary}
                className="new-campaign__modal"
            >
                <p>
                    The total bet of the free rounds campaign is {estimatedTotalBets()}. Are you
                    sure you want to launch
                </p>
                <div className="new-campaign-form-total__footer">
                    <SecondaryButton onClick={handleCancelSecondary}>{t('no')}</SecondaryButton>
                    <MainButton onClick={() => handleSubmit()}>{t('yes')}</MainButton>
                </div>
            </Modal>
            <Modal
                destroyOnClose
                width="90%"
                title={t('new_campaign_creation')}
                open={mainModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="new-campaign__modal"
            >
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <div style={{ display: 'flex' }}>
                            <div className="new-campaign_form-side">
                                <FormikField name="campaign_name" category="input" />
                                <FormikField name="promotion_type" category="select">
                                    <Select.Option key={'free_rounds'} value={'free_rounds'}>
                                        {t('free_rounds')}
                                    </Select.Option>
                                </FormikField>
                                <FormikField name="timezone" category="select">
                                    {timezoneList.map((timezone: ITimeZoneListItem) => (
                                        <Select.Option key={timezone.id} value={timezone.id}>
                                            {`UTC${timezone.offset} ${timezone.area}/${timezone.location}`.toUpperCase()}
                                        </Select.Option>
                                    ))}
                                </FormikField>
                                <FormikField name="start_at" category="datePicker" />
                                <FormikField name="end_at" category="datePicker" />
                                <FormikField
                                    name="expires_in_days"
                                    category="input"
                                    type="number"
                                />
                                <div className="new-campaign-form-item">
                                    <label
                                        className="new-campaign-form-item__label"
                                        htmlFor="strategy"
                                    >
                                        {t('players')}
                                    </label>
                                    <div className="new-campaign-form-item__field">
                                        <div className="new-campaign-form-item__with-upload">
                                            <Field
                                                disabled={values.isFile}
                                                id="players"
                                                name="players"
                                                type="number"
                                                placeholder={t('players_placeholder')}
                                                component={FormikInput}
                                            />
                                            <Field name="isFile">
                                                {({ field, form, meta }: any) => (
                                                    <FormikSwitch
                                                        checked={values.isFile}
                                                        id="isFile"
                                                        name="isFile"
                                                        meta={meta}
                                                        form={form}
                                                        field={field}
                                                        onChange={handleSwitchChange}
                                                    />
                                                )}
                                            </Field>
                                            <Field
                                                disabled={!values.isFile}
                                                name="file"
                                                component={FormikUpload}
                                            >
                                                <Button
                                                    disabled={!values.isFile}
                                                    icon={<UploadOutlined />}
                                                >
                                                    {t('upload_file')}
                                                </Button>
                                            </Field>
                                        </div>
                                        <ErrorMessage
                                            className="new-campaign-form-item__error"
                                            name="players"
                                            component="div"
                                        />
                                        <ErrorMessage
                                            className="new-campaign-form-item__error"
                                            name="file"
                                            component="div"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div style={{ flex: 1, paddingLeft: '1rem' }}>
                                <FormikField name="countries" category="select" mode="multiple">
                                    <Select.Option value="all">{t('all_countries')}</Select.Option>
                                    {countriesList.map((country: ICountry) => (
                                        <Select.Option key={country.name} value={country.name}>
                                            {country.name}
                                        </Select.Option>
                                    ))}
                                </FormikField>
                                <FormikField
                                    name="rules_url"
                                    category="input"
                                />
                                <FormikField name="currency" category="select">
                                    {currencyList.map((currency: any) => (
                                        <Select.Option key={currency.code} value={currency.code}>
                                            {currency.code}
                                        </Select.Option>
                                    ))}
                                </FormikField>
                                <FormikField name="bet" category="select">
                                    {betSizes.map((bet: number) => (
                                        <Select.Option key={bet} value={bet}>
                                            {bet /
                                                currencyUtils.getCurrencyDataByCode(
                                                    formik.values.currency,
                                                ).subunit}
                                        </Select.Option>
                                    ))}
                                </FormikField>

                                <FormikField name="game_name" category="select" mode="multiple">
                                    <Select.Option value="all">{t('all_games')}</Select.Option>
                                    {filteredGamesList.map((game: { id: number; name: string }) => (
                                        <Select.Option key={game.name} value={game.name}>
                                            {game.name}
                                        </Select.Option>
                                    ))}
                                </FormikField>

                                <FormikField
                                    name="number_of_free_rounds"
                                    category="input"
                                    type="number"
                                />
                                <div className="new-campaign-form-total">
                                    <p className="new-campaign-form-total__item">
                                        {t('est_bet_per_player') + `: ${estimatedBetsPerPlayer()}`}
                                    </p>
                                    <p className="new-campaign-form-total__item">
                                        {t('est_total_bets') + `: ${estimatedTotalBets()}`}
                                    </p>
                                    <p className="new-campaign-form-total__item">
                                        {t('number_of_uploaded_players') +
                                            `: ${Number(formik.values.players)}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="new-campaign-form-total__footer">
                            <SecondaryButton onClick={() => handleCancel()}>
                                {t('close')}
                            </SecondaryButton>
                            <MainButton type="submit">{t('create_campaign')}</MainButton>
                        </div>
                    </Form>
                </FormikProvider>
            </Modal>
            <Overlay
                isVisible={mainModalVisible || secondartModalVisible}
                switchState={setMainModalVisible}
            />
        </div>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createPromotion: (data: any) => dispatch(createPromotion(data)),
    createPromotionWithCSV: (data: any) => dispatch(createPromotionWithCSV(data)),
    getPromotions: (data: any) => dispatch(getPromotions(data)),
    getGameConfigurations: () => dispatch(getAllGameConfigurationsByUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionModal);
